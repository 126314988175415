<template>
  <b-card>
    <b-row>
      <b-col class="hp-flex-none w-auto">
        <b-avatar class="bg-secondary-4 hp-bg-color-dark-secondary" size="48px">
          <i
            class="iconly-Light-Buy text-secondary"
            style="font-size: 24px"
          ></i>
        </b-avatar>
      </b-col>

      <b-col class="pl-0">
        <h3 class="mb-4 mt-8">
          <!-- {{ totalTheftVehicles }} -->
            123
        </h3>

        <p class="hp-p1-body mb-0 text-black-80 hp-text-color-dark-30">
          Total Theft Vehicles
        </p>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BAvatar } from "bootstrap-vue";
import axios from "axios";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
  },
  data() {
    return {
      totalTheftVehicles: 0, // Initialize with a default value
    };
  },
  mounted() {
    this.getDashboardData();
  },
  methods: {
    async getDashboardData() {
      try {
        const response = await axios.get("/dashboard");
        if (response.data.success) {
          this.totalTheftVehicles = response.data.data.total_theft_vehicles;
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    },
  },
};
</script>
